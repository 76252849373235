<template>
  <v-app>
    <v-main class="mainwrap">
      <SpanCalc />
    </v-main>
  </v-app>
</template>

<script>
import SpanCalc from '../src/views/SpanCalc.vue';

export default {
  name: 'App',

  data: () => ({
    //
  }),

  components: {
    SpanCalc,
  },
};
</script>

<style>
*,
html,
body .v-application,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption,
.v-application .overline {
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.mainwrap {
  overflow: hidden !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
</style>