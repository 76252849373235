import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCRjS1vUMyVdullTvDvqsQCuI1oeITqpfE",
    authDomain: "span-calculator.firebaseapp.com",
    projectId: "span-calculator",
    storageBucket: "span-calculator.appspot.com",
    messagingSenderId: "355656477237",
    appId: "1:355656477237:web:502551746d58a5c9b95f7c",
    measurementId: "G-VHP5DS9LNQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');


Vue.config.productionTip = false

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')