<template>
  <hello-world />
</template>

<script>
import HelloWorld from '../components/HelloWorld'

export default {

  components: {
    HelloWorld,
  },
}
</script>

<style>
*,
html,
body .v-application,
.v-application .display-1,
.v-application .headline,
.v-application .title,
.v-application .subtitle-1,
.v-application .subtitle-2,
.v-application .body-1,
.v-application .body-2,
.v-application .caption,
.v-application .overline {
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.6);
  border-radius: 16px;
}

.cliidsty tr.v-row-group__header {
  color: black !important;
  font-weight: 700;
}

.v-input--selection-controls {
  margin-top: 0 !important;
}
</style>