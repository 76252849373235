<template>
  <div>
    <v-snackbar class="pt-6 pr-6" style="z-index: 6 !important;" transition="slide-x-reverse-transition"
      v-model="snackbar" timeout="3000" :value="true" :color="snackbarclr" absolute outlined top right>
      <v-icon class="mr-2" :color="snackbarclr">mdi-alert-outline</v-icon>
      {{ snackmsgbar }}
    </v-snackbar>
    <div>
      <v-card style="background-color: white; position: fixed;z-index:3;" height="50px" width="100%" class="elevation-0"
        outlined>
        <v-container class="py-0">
          <img src="@/assets/Zebulogo.svg" width="80px">
        </v-container>
      </v-card>
      <v-container>
        <div class="mt-16">
          <v-row>
            <v-col cols="12" md="4" class="pr-md-4">
              <v-row no-gutters>
                <v-col cols="10">
                  <p class="font-weight-bold title">Span calculator</p>

                </v-col>
                <v-col cols="2" class="text-right">
                  <v-btn x-small fab icon @click="refeshData()">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                      <path fill="currentColor"
                        d="M16.63 7.05a6.78 6.78 0 0 0-11.2 3.29.49.49 0 0 1-.47.37H2.65a.48.48 0 0 1-.48-.57 10 10 0 0 1 16.74-5.37l1.44-1.44A.97.97 0 0 1 22 4v5.4c0 .54-.43.98-.97.98h-5.4a.97.97 0 0 1-.69-1.65l1.69-1.69ZM2.97 13.61h5.4a.97.97 0 0 1 .69 1.66l-1.69 1.68a6.78 6.78 0 0 0 11.2-3.29.49.49 0 0 1 .47-.37h2.31c.3 0 .53.27.48.57a10 10 0 0 1-16.74 5.37l-1.44 1.44A.97.97 0 0 1 2 20v-5.4c0-.54.43-.98.97-.98Z">
                      </path>
                    </svg>
                  </v-btn>
                </v-col>
              </v-row>
              <!-- {{ items }} -->
              <v-autocomplete v-model="model" dense :items="items" :loading="isLoading" :search-input.sync="search"
                clearable hide-details hide-selected item-text="tsym" @change="search(val)" item-value="token"
                label="Contract" prepend-inner-icon="mdi-magnify" append-icon="" no-filter outlined
                return-object auto-select-first oninput="this.value = this.value.toUpperCase()">

                <template v-slot:no-data>
                  <v-col class="text-center mx-auto pa-6">
                    <p class="font-weight-bold mb-2 black--text">
                      {{
                        nodata == null ? "Type more than 2 letter" : "No Contract Found" }} </p>
                    <span class="body-2 mb-5 grey--text">
                      {{
                        nodata == null ? "Eg. for Nifty Type: Nif" : "Try search for diff name" }}</span>
                  </v-col>
                </template>
                <template v-slot:items="{ items }">
                  <v-list-item-content>
                    <v-list-item-title>{{ items.tsym }}</v-list-item-title>
                    <v-list-item-subtitle>{{ items.exch }}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-row class="mt-4">
                <v-col>
                  <v-text-field label="Quantity" dense outlined type="number" v-model="qtys" :step="qtys"
                    oninput="this.value=this.value.replace(/[^0-9]+/g, '')" hide-details>
                  </v-text-field>
                </v-col>
                <v-col class="radiobtnsty">
                  <v-radio-group class="mt-1 mx-auto" row v-model="bayorsell">
                    <!-- <v-card outlined class="pa-2"> -->
                    <v-radio label="Buy" value="B" hide-details></v-radio>
                    <!-- </v-card> -->
                    <!-- <v-card outlined class="pa-2"> -->
                    <v-radio label="Sell" value="S" hide-details></v-radio>
                    <!-- </v-card> -->
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn @click="resetScript" :disabled="getspandata.length == 0" block color="grey"
                    class="white--text elevation-0">Reset</v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn @click="addScript" :disabled="model == null ? true : nodata == 'allready' ? true : false" block
                    class="white--text elevation-0" color="#0065be">+ Add</v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-divider class="d-none d-md-block" vertical></v-divider>
            <v-col cols="12" md="4" class="pl-md-4">
              <v-card outlined class="pt-3">
                <p class="px-4 mb-2">Combine margin requirements</p>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-chip class="pa-2" color="rgb(55, 125, 255)" x-small>
                    </v-chip>
                    <v-list-item-content class="pl-3">
                      <v-list-item-subtitle>Span</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="text-right">
                      <v-list-item-title>&#8377;
                        <span v-if="chartdata.span"> {{ chartdata.span }}</span>
                        <span v-else> 0.00</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-0 my-md-1"></v-divider>
                  <v-list-item>
                    <v-chip class="pa-2" color="rgb(55, 125, 255)" x-small>
                    </v-chip>
                    <v-list-item-content class="pl-3">
                      <v-list-item-subtitle>Exposure</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="text-right">
                      <v-list-item-title>&#8377;
                        <span v-if="chartdata.expo"> {{ chartdata.expo }}</span>
                        <span v-else> 0.00</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="my-0 my-md-1"></v-divider>
                  <v-list-item>
                    <v-chip class="pa-2" x-small>
                    </v-chip>
                    <v-list-item-content class="pl-3">
                      <v-list-item-subtitle>Total margin</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content class="text-right">
                      <v-list-item-title>&#8377;
                        <span v-if="chartdata.total"> {{ chartdata.total }}</span>
                        <span v-else> 0.00</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" md="4">
              <v-card outlined class="pt-3">
                <p class="px-4 mb-2">Margin benefits</p>
                <v-divider></v-divider>
                <div class="chartsty">
                  <canvas class="pa-10 pa-md-8 my-8 my-md-n16" id="myChart">
                  </canvas>
                  <div color="transparent" class="charttext text-center">
                    <p class="mb-0 caption">Benefits</p>
                    <p class="subtitle-2 font-weight-bold mb-0">&#8377; {{ alltoo?.toFixed(2) }}</p>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12" md="8" class="ml-auto">
              <v-card outlined class="rounded-lg elevation-0">
                <v-data-table disable-sort :headers="spantablehead" :items="getspandata"
                  :items-per-page="getspandata.length" class="cliidsty elevation-0 rounded-lg" hide-default-footer>
                  <template v-slot:[`item.action`]="{ item }">
                    <svg @click="deleteItem(item)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="24"
                      height="24">
                      <path fill="currentColor" fill-rule="evenodd"
                        d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z">
                      </path>
                    </svg>
                  </template>
                  <template v-slot:[`item.buysell`]="{ item }">
                    <v-chip class="ma-2" :color="item.buysell == 'B' ? '#e3effd' : '#ffebec'"
                      :text-color="item.buysell == 'B' ? '#1e53e5' : '#cc2f3c'">
                      <span class="font-weight-bold">{{ item.buysell == 'B' ? 'BUY' : 'SELL' }}</span>
                    </v-chip>
                  </template>
                  <template v-slot:no-data>
                    <v-col class="mx-auto pa-15">
                      <span>No data available</span>
                    </v-col>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import axios from 'axios';
import { apiurl, getoken } from '../apiurl';

export default {

  data: () => ({
    snackbar: false,
    snackbarclr: "default",
    snackmsgbar: "",

    isLoading: false,
    items: [],
    model: null,
    search: null,
    nodata: null,
    bayorsell: 'B',
    qtys: 0,

    getspandata: [],
    grpspan: [],
    chartdata: {},

    alltoo: 0.00,
    doughnuts: [1],
    doughnutcolor: ["rgba(55, 125, 255, 0.35)"],

    uidid: '',
    spanid: '',
    sessions: '',
  }),

  created() {
    let config = {
      method: 'post',
      url: `${getoken}/getoken`,
      headers: {},
    };

    let axiosThis = this;
    axios.request(config)
      .then((response) => {
        axiosThis.uidid = response.data.uid;
        axiosThis.spanid = response.data.uid;
        axiosThis.sessions = response.data.token;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  mounted() {
    this.setChart();
  },

  methods: {
    addScript() {
      if (this.model != null) {
        var data = `jData={"uid":"${this.uidid}","exch":"${this.model.exch}","token":"${this.model.token}"}&jKey=${this.sessions}`;
        var Quotesconfig = {
          method: 'post',
          url: `${apiurl}/GetQuotes`,
          headers: {
            'Content-Type': 'text/plain'
          },
          data: data
        };
        console.log("GetQuotes data", data);

        let axiosThis = this;
        axios(Quotesconfig)
          .then(function (response) {
            console.log("GetQuotes", response.data);
            let GetQuotes = response.data;
            let getspandata = null;
            if (response.data.strprc) {
              if (axiosThis.bayorsell == 'B') {
                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `${axiosThis.qtys}`, "optt": `${response.data.optt}`, "strprc": `${response.data.strprc}` })
              } else if (axiosThis.bayorsell == 'S') {
                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `-${axiosThis.qtys}`, "optt": `${response.data.optt}`, "strprc": `${response.data.strprc}` })
              }
            } else {
              if (axiosThis.bayorsell == 'B') {
                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `${axiosThis.qtys}`, "optt": `${response.data.optt}` })
              } else if (axiosThis.bayorsell == 'S') {
                getspandata = ({ "prd": "M", "exch": `${response.data.exch}`, "tsym": `${response.data.tsym}`, "symname": `${response.data.symname}`, "instname": `${response.data.instname}`, "exd": `${response.data.exd}`, "netqty": `-${axiosThis.qtys}`, "optt": `${response.data.optt}` })
              }
            }
            // "buyqty": `${axiosThis.qtys}`, "sellqty": '0',
            // "buyqty": '0', "sellqty": `${axiosThis.qtys}`,
            axiosThis.grpspan.push(getspandata)
            let singlespan = JSON.stringify(getspandata)
            if (singlespan) {
              let data = `jData:{"actid":"${axiosThis.spanid}", "pos":[${singlespan}]}`;
              let config = {
                method: 'post',
                url: `${apiurl}/SpanCalc`,
                headers: {
                  'Content-Type': 'text/plain'
                },
                data: data
              };
              console.log(data, "data");
              axios.request(config)
                .then((response) => {
                  console.log("SpanCalc", response.data);
                  let getspan = response.data;
                  if (response.data.stat == "Ok") {
                    axiosThis.getspandata.push({ "exch": `${GetQuotes.exch}`, "tsym": `${GetQuotes.tsym}`, "buysell": `${axiosThis.bayorsell}`, "qty": `${axiosThis.qtys}`, "expo": `${getspan.expo}`, "expo_trade": `${getspan.expo_trade}`, "span": `${getspan.span}`, "span_trade": `${getspan.span_trade}`, "total": Number((Number(getspan.span) + Number(getspan.expo))?.toFixed(2)) })
                    axiosThis.chartSpan();
                    axiosThis.model = null;
                    axiosThis.qtys = 0;
                    axiosThis.bayorsell = 'B';
                  } else {
                    axiosThis.snackbar = true;
                    axiosThis.snackbarclr = "warning";
                    axiosThis.snackmsgbar = response.data.emsg;
                  }
                  console.log("SpanCalc calc getspan", axiosThis.getspandata);
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    chartSpan() {
      let grpin = JSON.stringify(this.grpspan)
      console.log("SpanCalc sss", this.grpspan, grpin);
      if (this.getspandata.length > 0) {
        this.chartdata = {};
        let data = `jData:{"actid":"${this.spanid}", "pos":${grpin}}`;
        let config = {
          method: 'post',
          url: `${apiurl}/SpanCalc`,
          headers: {
            'Content-Type': 'text/plain'
          },
          data: data
        };
        console.log("data", data);
        let axiosThis = this;
        axios.request(config)
          .then((response) => {
            console.log("SpanCalc grp", response.data);
            if (response.data.stat == "Ok") {
              let tolcalc = response.data
              tolcalc['total'] = Number(response.data.span) + Number(response.data.expo)
              axiosThis.chartdata = response.data;

            } else {
              axiosThis.snackbar = true;
              axiosThis.snackbarclr = "warning";
              axiosThis.snackmsgbar = response.data.emsg;
            }
            axiosThis.doughnutUpdate();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.chartdata = [];
        this.alltoo = 0.00;
        this.doughnuts = [1];
        this.doughnutcolor = ["rgba(55, 125, 255, 0.35)"];
        this.doughnut.destroy();
        this.setChart();
      }

    },
    doughnutUpdate() {
      console.log("this.doughnutUpdate 2", this.doughnut);
      if ((this.getspandata.length > 1) && (Number(this.chartdata.expo) > 0) && (Number(this.chartdata.span) + Number(this.chartdata.expo) > 0)) {
        let sumof = this.getspandata.reduce((n, { total }) => n + total, 0)
        let alltol = Number(this.chartdata.span) + Number(this.chartdata.expo)
        this.alltoo = sumof - alltol;
        // console.log("abc",this.getspandata, sumof,alltol,'|', alltoo,'|');
        this.doughnuts = [this.alltoo, (Number(this.chartdata.span) + Number(this.chartdata.expo))];
        this.doughnutcolor = ["#377dff", "rgba(55, 125, 255, 0.35)"];
      } else {
        this.alltoo = 0.00;
        this.doughnuts = [1];
        this.doughnutcolor = ["rgba(55, 125, 255, 0.35)"];
      }
      this.doughnut.destroy();
      this.setChart();
    },
    setChart() {
      const setdoughnut = document.getElementById("myChart").getContext("2d");
      this.doughnut = new Chart(setdoughnut, {
        type: 'doughnut',
        data: {
          datasets: [{
            data: this.doughnuts,
            backgroundColor: this.doughnutcolor,
            borderWidth: 2,
            radius: "100%"
          }]
        },
        options: {
          cutout: "80%",
          rotation: -90,
          circumference: 180,
          events: [],
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
        }
      });
    },
    deleteItem(item) {
      let delset = this.getspandata.indexOf(item)
      this.getspandata.splice(delset, 1)
      let chartupdate = [item.tsym]
      let mardata = this.grpspan.filter(item => !chartupdate.includes(item.tsym));
      this.grpspan = [];
      this.grpspan = mardata;
      let axiosThis = this;
      axiosThis.chartSpan();
    },
    resetScript() {
      this.model = null;
      this.qtys = 0;
      this.bayorsell = 'B';
      this.grpspan = [];
      this.getspandata = [];
      this.chartdata = {};
      this.doughnutUpdate();
    },
    refeshData() {
      if (this.getspandata.length > 0) {
        this.chartdata = {};
        this.chartSpan();
      }
    }
  },

  watch: {
    search(val) {
      let checkval = null;
      checkval = this.getspandata.filter((element) => element.tsym === val)
      if (val.length > 2 && checkval.length == 0) {
          this.isLoading = true
          let data = `jData={"uid":"${this.uidid}","stext":"${val}"}&jKey=${this.sessions}`;
          let config = {
            method: 'post',
            url: `${apiurl}/SearchScrip`,
            headers: {
              'Content-Type': 'text/plain'
            },
            data: data
          };

          let axiosThis = this;
          axios.request(config)
            .then((response) => {
              // console.log("SearchScrip", response.data);
              axiosThis.nodata = null;
              if (response.data.stat == "Ok" || response.data.values) {
                axiosThis.items = [];
                for (const element of response.data.values) {
                  if (element.exch == "BFO" || element.exch == "NFO" || element.exch == "MCX" || element.exch == "CDS") {
                    if (element.instname !== "COM") {
                      axiosThis.items.push({ "exch": element.exch, "instname": element.instname, "ls": element.ls, "optt": element.optt, "pp": element.pp, "ti": element.ti, "token": element.token, "tsym": element.tsym });
                    }
                  }
                }
                axiosThis.isLoading = false
                if (axiosThis.model) {
                  axiosThis.qtys = Number(axiosThis.model.ls);
                }

              } else if (response.data.emsg == "No Data :   ") {
                axiosThis.items = [];
                axiosThis.qtys = 0;
                axiosThis.isLoading = false;
                axiosThis.nodata = 'noooo';
              }
            })
            .catch((error) => {
              console.log(error);
              if (error) {
                axiosThis.items = [];
                axiosThis.qtys = 0;
                axiosThis.isLoading = false;
                axiosThis.nodata = 'noooo';
              }
            });
      } else {
        if (checkval.length != 0) {
          this.snackbar = true;
          this.snackbarclr = "warning";
          this.snackmsgbar = "You can't add the same script in contract.";
          this.nodata = 'allready'
        } else {
          this.nodata = 'noooo';
        }
        this.qtys = 0;
        this.model = null;
        this.items = [];
      }
    },
  },

  computed: {
    spantablehead() {
      return [
        { text: 'Exch', value: "exch" },
        { text: 'Symbol', value: "tsym" },
        { text: 'Buy/Sell', value: "buysell" },
        { text: 'Qty', value: 'qty', align: 'right' },
        { text: 'Span margin', value: 'span', align: 'right' },
        { text: 'Exposure', value: 'expo', align: 'right' },
        { text: 'Total', value: 'total', align: 'right' },
        { text: '', value: "action" },
      ]
    }
  },
}
</script>

<style>
.chartsty {
  position: relative;
  width: 100%;
}

.charttext {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -70%);
}
</style>